import React from 'react';

import spingo_logo from '../assets/img/general/spingo-logo-light.png';

// * IMPORTS MATERIAL UI
// import Avatar from '@mui/material/Avatar';
// import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Derechos Reservados © '}
            <Link color="inherit" href="https://spingo.mx/">
				SpinGo
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export const LoginComponent = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          	email: data.get('email'),
          	password: data.get('password'),
        });
    };

    return (
        <ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					}}
				>
					<img src={spingo_logo} className="img-logo" alt="logo" />

					<Typography component="h1" variant="h5">
						Iniciar Sesión
					</Typography>

					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

						<TextField margin="normal" required fullWidth id="email" label="Correo Electrónico" name="email" autoComplete="email" autoFocus />

						<TextField margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" />
						
						<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Recordarme" />
						
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Iniciar Sesión </Button>
					
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2"> ¿Has olvidado tu contraseña? </Link> 
							</Grid>
							<Grid item> 
								<Link href="#" variant="body2"> {"¿No tienes una cuenta? Registrate"} </Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
      	</ThemeProvider>
    )
}
