import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import { LoginComponent } from './components/LoginComponent';

function App() {
	return (
		<div className="App">
			<LoginComponent />
		</div>
	);
}

export default App;

